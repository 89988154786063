.root {
  --backgroundOffset: 0;
  --offsetShift: 1;

  /*
   * Shift the offset so that we don't start in the extended
   * blackness of space.
   */
  --adjustedOffset: calc(var(--backgroundOffset) + 1);

  /* NB Cannot set background color here as it will appear above `light` */
}

.light {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  /* Ensure fixed aurora sits behind flow content */
  z-index: -1;
  background-color: #101010;
  background-image: linear-gradient(
    110deg,
    #101010,
    #101010,
    #69413b,
    #d79871,
    #c1af70,
    #686465,
    #101010,
    #101010
  );
  background-size: 650vw 100vh;
  background-position-x: calc(var(--adjustedOffset) * -100vw);
  transition: background-position 1s ease-out;
}

.stars {
  background-image: url('/images/starfield.png');
  background-repeat: repeat;
  background-size: 1920px 1080px;
  background-position-x: calc(var(--adjustedOffset) * -5vw);
  transition: background-position 1s ease-out;
}
