.root {
}

.planet {
  position: fixed;
  width: 150vw;
  left: -25vw;
  top: 0;
  transition: transform 0.2s ease-out;
  transform: translateY(
    max(max(calc(100vh - 30vw), calc(100vh - 300px)), 70vh)
  );

  z-index: 50;
}

.pagination {
  width: 100%;
  z-index: 100;

  position: fixed;
  bottom: min(20vw, 150px);

  pointer-events: none;
}

.menu {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: space-between;
}

.arrowLink {
  display: inline-block;
  background-color: #fafafa;
  width: 100px;
  height: 100px;
  overflow: hidden;
  mask: url(/images/icons/rotate.svg) 0 0/100px 100px;
  color: transparent;
  pointer-events: all;
}

.arrowLink:hover,
.arrowLink:focus-visible {
  background-color: var(--color-brightGreen);
  cursor: pointer;
}

.previousArrow {
  /* transform: scale(50%); */
}

.nextArrow {
  transform: scaleX(-1);
}

@media (max-width: 700px) {
  .planet {
    width: 160vw;
    left: -30vw;
    /* transform: translateY(10px); */
  }

  .pagination {
    bottom: min(10vw, 150px);
  }
}
