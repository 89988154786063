/* latin-ext */
@font-face {
  font-family: '__Syne_Mono_d7113f';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/e9b608660449a9de-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Syne_Mono_d7113f';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/8879ceb5356d39f9-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Syne_Mono_Fallback_d7113f';src: local("Arial");ascent-override: 74.24%;descent-override: 22.07%;line-gap-override: 0.00%;size-adjust: 124.60%
}.__className_d7113f {font-family: '__Syne_Mono_d7113f', '__Syne_Mono_Fallback_d7113f';font-weight: 400;font-style: normal
}

@font-face {
font-family: '__headingFont_cab6b4';
src: url(/_next/static/media/867dce8beb592c64-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__headingFont_Fallback_cab6b4';src: local("Arial");ascent-override: 75.48%;descent-override: 3.98%;line-gap-override: 0.00%;size-adjust: 98.07%
}.__className_cab6b4 {font-family: '__headingFont_cab6b4', '__headingFont_Fallback_cab6b4'
}.__variable_cab6b4 {--font-heading: '__headingFont_cab6b4', '__headingFont_Fallback_cab6b4'
}

:root {
  --color-white: #fafafa;
  --color-black: #010101;
  --color-brightGreen: #4dffa1;
  --color-red: #6d303e;
}

html {
  margin: 0;
  padding: 0;
  background: var(--color-black);
  color: var(--color-white);
  font-size: 18px;
  line-height: 1.5;
}

body {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
p {
  margin: 0;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.layout_root___cq4U {
}

.layout_content__jhh_K {
  position: relative;
  padding: 20px;
}

.layout_background___kCb_ {
  box-sizing: border-box;
  min-height: 100vh;
  width: 100%;
}

/* Pad bottom of content to fit around Mars */
.layout_paddedContent__gX7CX {
  padding-bottom: max(25vw, 450px);
}

.layout_navigation__U8iXL {
  z-index: 50;
}

@media (max-width: 700px) {
  .layout_content__jhh_K {
    padding-top: 60px;
  }
}

.Globe_root__FUN4d {
  --Globe-stepCount: 5;
  --Globe-position: 0;
  aspect-ratio: 1;
  width: 100%;

  position: relative;
  pointer-events: none;
}

.Globe_layer__BXcxK {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
}

.Globe_lensFlare__vssyB {
  border-radius: 50%;
  width: 85%;
  aspect-ratio: 1;
  left: 15%;
  top: 5%;
  box-shadow:
    0 0 45px 15px #ffffffdd,
    inset 0 0 200px 100px #ffffff99;
}

.Globe_aurora__7QncF {
  border-radius: 50%;
  width: 100%;
  aspect-ratio: 1;
  left: 0;
  top: 0;
  box-shadow:
    0 0 45px 15px #9f260866,
    inset 0 0 200px 100px #9f260866;
  animation: Globe_auroraShift__ZnMLR 20s linear infinite;
}

@keyframes Globe_auroraShift__ZnMLR {
  0% {
    transform: translateX(-20px) translateY(-25px);
  }
  25% {
    transform: translateX(25px) translateY(-20px);
  }
  50% {
    transform: translateX(20px) translateY(25px);
  }
  75% {
    transform: translateX(-25px) translateY(20px);
  }
  100% {
    transform: translateX(-20px) translateY(-25px);
  }
}

.Globe_planetSlice__8p1t8 {
  --sliceRotationOffset: 0deg;
  --sliceRotationRate: 1;
  --sliceScale: 1;

  transition: transform 1s ease-out;

  --sliceRotation: calc(
    (360deg / var(--Globe-stepCount) * var(--sliceRotationRate)) *
      var(--Globe-position) * -1 + var(--sliceRotationOffset)
  );
  transform: scale(var(--sliceScale)) rotateZ(var(--sliceRotation));

  background: no-repeat center top/cover url('/images/mars/mars-sprite.png');
  background-image: -webkit-image-set(
    url('/images/mars/mars-sprite@2x.webp') 2x,
    url('/images/mars/mars-sprite.webp') 1x,
    url('/images/mars/mars-sprite.png') 1x
  );
  background-image: -webkit-image-set(
    url('/images/mars/mars-sprite@2x.webp') type('image/webp') 2x,
    url('/images/mars/mars-sprite.webp') type('image/webp') 1x,
    url('/images/mars/mars-sprite.png') type('image/png') 1x
  );
  background-image: image-set(
    url('/images/mars/mars-sprite@2x.webp') type('image/webp') 2x,
    url('/images/mars/mars-sprite.webp') type('image/webp') 1x,
    url('/images/mars/mars-sprite.png') type('image/png') 1x
  );

  overflow: hidden;
}

.Globe_crust__UuShc {
  --sliceRotationOffset: 5deg;
  background-position-y: top;
  background-size: cover;

  width: 100%;
  height: 100%;
  left: 0%;
  top: 0;
}

.Globe_terrain__iFRzP {
  --sliceRotationOffset: 25deg;
  --sliceRotationRate: 0.9;
  background-position-y: bottom;
  width: 96%;
  height: 96%;
  left: 2%;
  top: 2%;
}

.Globe_scrub__z6JYE {
  --sliceRotationOffset: -5deg;
  --sliceRotationRate: 1.1;
  background-position-y: center;
  width: 90%;
  height: 90%;
  left: 5%;
  top: 5%;
}

.OrbitalNavigation_root__ErDpd {
}

.OrbitalNavigation_planet__CP9sp {
  position: fixed;
  width: 150vw;
  left: -25vw;
  top: 0;
  transition: transform 0.2s ease-out;
  transform: translateY(
    max(max(calc(100vh - 30vw), calc(100vh - 300px)), 70vh)
  );

  z-index: 50;
}

.OrbitalNavigation_pagination__2FUrm {
  width: 100%;
  z-index: 100;

  position: fixed;
  bottom: min(20vw, 150px);

  pointer-events: none;
}

.OrbitalNavigation_menu__lCZBR {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: space-between;
}

.OrbitalNavigation_arrowLink__7Xa06 {
  display: inline-block;
  background-color: #fafafa;
  width: 100px;
  height: 100px;
  overflow: hidden;
  -webkit-mask: url(/images/icons/rotate.svg) 0 0/100px 100px;
          mask: url(/images/icons/rotate.svg) 0 0/100px 100px;
  color: transparent;
  pointer-events: all;
}

.OrbitalNavigation_arrowLink__7Xa06:hover,
.OrbitalNavigation_arrowLink__7Xa06:focus-visible {
  background-color: var(--color-brightGreen);
  cursor: pointer;
}

.OrbitalNavigation_previousArrow__6Mobm {
  /* transform: scale(50%); */
}

.OrbitalNavigation_nextArrow__HAWi0 {
  transform: scaleX(-1);
}

@media (max-width: 700px) {
  .OrbitalNavigation_planet__CP9sp {
    width: 160vw;
    left: -30vw;
    /* transform: translateY(10px); */
  }

  .OrbitalNavigation_pagination__2FUrm {
    bottom: min(10vw, 150px);
  }
}

.StarfieldBackground_root__Cr5gY {
  --backgroundOffset: 0;
  --offsetShift: 1;

  /*
   * Shift the offset so that we don't start in the extended
   * blackness of space.
   */
  --adjustedOffset: calc(var(--backgroundOffset) + 1);

  /* NB Cannot set background color here as it will appear above `light` */
}

.StarfieldBackground_light__Oc9Ms {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  /* Ensure fixed aurora sits behind flow content */
  z-index: -1;
  background-color: #101010;
  background-image: linear-gradient(
    110deg,
    #101010,
    #101010,
    #69413b,
    #d79871,
    #c1af70,
    #686465,
    #101010,
    #101010
  );
  background-size: 650vw 100vh;
  background-position-x: calc(var(--adjustedOffset) * -100vw);
  transition: background-position 1s ease-out;
}

.StarfieldBackground_stars__Yue4t {
  background-image: url('/images/starfield.png');
  background-repeat: repeat;
  background-size: 1920px 1080px;
  background-position-x: calc(var(--adjustedOffset) * -5vw);
  transition: background-position 1s ease-out;
}

