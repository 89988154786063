:root {
  --color-white: #fafafa;
  --color-black: #010101;
  --color-brightGreen: #4dffa1;
  --color-red: #6d303e;
}

html {
  margin: 0;
  padding: 0;
  background: var(--color-black);
  color: var(--color-white);
  font-size: 18px;
  line-height: 1.5;
}

body {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
p {
  margin: 0;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
