.root {
  --Globe-stepCount: 5;
  --Globe-position: 0;
  aspect-ratio: 1;
  width: 100%;

  position: relative;
  pointer-events: none;
}

.layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
}

.lensFlare {
  border-radius: 50%;
  width: 85%;
  aspect-ratio: 1;
  left: 15%;
  top: 5%;
  box-shadow:
    0 0 45px 15px #ffffffdd,
    inset 0 0 200px 100px #ffffff99;
}

.aurora {
  border-radius: 50%;
  width: 100%;
  aspect-ratio: 1;
  left: 0;
  top: 0;
  box-shadow:
    0 0 45px 15px #9f260866,
    inset 0 0 200px 100px #9f260866;
  animation: auroraShift 20s linear infinite;
}

@keyframes auroraShift {
  0% {
    transform: translateX(-20px) translateY(-25px);
  }
  25% {
    transform: translateX(25px) translateY(-20px);
  }
  50% {
    transform: translateX(20px) translateY(25px);
  }
  75% {
    transform: translateX(-25px) translateY(20px);
  }
  100% {
    transform: translateX(-20px) translateY(-25px);
  }
}

.planetSlice {
  --sliceRotationOffset: 0deg;
  --sliceRotationRate: 1;
  --sliceScale: 1;

  transition: transform 1s ease-out;

  --sliceRotation: calc(
    (360deg / var(--Globe-stepCount) * var(--sliceRotationRate)) *
      var(--Globe-position) * -1 + var(--sliceRotationOffset)
  );
  transform: scale(var(--sliceScale)) rotateZ(var(--sliceRotation));

  background: no-repeat center top/cover url('/images/mars/mars-sprite.png');
  background-image: -webkit-image-set(
    url('/images/mars/mars-sprite@2x.webp') 2x,
    url('/images/mars/mars-sprite.webp') 1x,
    url('/images/mars/mars-sprite.png') 1x
  );
  background-image: image-set(
    url('/images/mars/mars-sprite@2x.webp') type('image/webp') 2x,
    url('/images/mars/mars-sprite.webp') type('image/webp') 1x,
    url('/images/mars/mars-sprite.png') type('image/png') 1x
  );

  overflow: hidden;
}

.crust {
  --sliceRotationOffset: 5deg;
  background-position-y: top;
  background-size: cover;

  width: 100%;
  height: 100%;
  left: 0%;
  top: 0;
}

.terrain {
  --sliceRotationOffset: 25deg;
  --sliceRotationRate: 0.9;
  background-position-y: bottom;
  width: 96%;
  height: 96%;
  left: 2%;
  top: 2%;
}

.scrub {
  --sliceRotationOffset: -5deg;
  --sliceRotationRate: 1.1;
  background-position-y: center;
  width: 90%;
  height: 90%;
  left: 5%;
  top: 5%;
}
