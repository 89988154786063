.root {
}

.content {
  position: relative;
  padding: 20px;
}

.background {
  box-sizing: border-box;
  min-height: 100vh;
  width: 100%;
}

/* Pad bottom of content to fit around Mars */
.paddedContent {
  padding-bottom: max(25vw, 450px);
}

.navigation {
  z-index: 50;
}

@media (max-width: 700px) {
  .content {
    padding-top: 60px;
  }
}
